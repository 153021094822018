<template>
  <div class="white product-card" :id="`product-${product.id}`">
    <v-card
      class="pa-3"
      flat
      rounded="md"
      :to="{ name: 'Product', params: { slug: product.slug } }"
    >
      <div class="product pa-0" :class="[promoClass]" @click="logClickProduct">
        <div class="promo-box-container d-flex align-center">
          <div
            class="promo-box text-uppercase product-badge novita mr-2"
            v-if="product.newProduct"
          >
            Novità
          </div>
          <ProductPromo
            :warehousePromo="product.warehousePromo"
            v-if="product.warehousePromo"
          />
        </div>
        <div class="header d-flex justify-space-between align-center">
          <div class="product-code">Cod. {{ productCode }}</div>
          <v-spacer />
          <div class="icons-box">
            <ProductAcqLimit :product="product" />
            <v-btn
              large
              color="secondary"
              v-if="
                isAuthenticated &&
                  isAuthenticated &&
                  hasRole('price_list_create')
              "
              icon
              @click.stop.prevent="addToPriceList(product)"
              @mousedown.stop
            >
              <v-icon>$iconSquareList</v-icon>
            </v-btn>
            <v-tooltip left :key="heartKey" v-if="!wishlistItem">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click.stop.prevent="addToFavorites"
                  @mousedown.stop
                >
                  <v-icon color="primary" v-if="highlight">$heartFull</v-icon>
                  <v-icon v-else class="heart">$heart</v-icon>
                </v-btn>
              </template>
              <span v-if="highlight">Articolo presente in una tua lista</span>
              <span v-else>Aggiungi ad una lista</span>
            </v-tooltip>
            <div class="product-classes-wrapper">
              <ProductClass v-if="productVendor" :pClass="productVendor" />
              <ProductClass
                :pClass="pClass"
                v-for="pClass in productClasses"
                :key="pClass.productClassId"
              />
            </div>
          </div>
        </div>
        <div class="d-flex body flex-column fill-width">
          <!-- icona surgelato -->
          <div class="product-info-frozen">
            <v-tooltip left v-if="product.productInfos.IS_SURGELATO == 'true'">
              <template v-slot:activator="{ on, attrs }">
                <div class="product-info mr-4" v-bind="attrs" v-on="on">
                  <img
                    aria-hidden
                    alt="Immagine surgelato"
                    src="/product-info/surgelato.svg"
                  />
                </div>
              </template>
              <span>Surgelato</span>
            </v-tooltip>
          </div>
          <img
            :src="product.mediaURL"
            class="product-img align-self-center"
            :width="$vuetify.breakpoint.xs ? 110 : 170"
            :height="$vuetify.breakpoint.xs ? 110 : 170"
            :alt="'Immagine' + product.name"
            :title="
              product.name + ' (' + product.codInt + '-' + product.codVar + ')'
            "
          />
          <div class="description">
            <div class="product-name">
              {{ product.name }}
            </div>
            <div class="product-descr">
              {{ product.description }}
            </div>
            <div class="product-brand">
              {{ product.shortDescr }}
            </div>
            <div
              class="product-descr"
              v-if="
                product.metaData &&
                  product.metaData.product_info &&
                  product.metaData.product_info.weight_description
              "
            >
              {{ product.metaData.product_info.weight_description }}
            </div>
            <v-spacer />
            <ProductPrice :product="product" />
          </div>
        </div>
      </div>
    </v-card>
    <div class="actions d-flex pa-3">
      <template v-if="product.available > 0">
        <div
          v-if="!showQntyInput"
          class="qty-wrap rounded-md"
          :class="{ 'not-empty': quantity > 0 }"
          transition="fab-transition"
        >
          <a
            role="button"
            class="minus"
            @click.stop.prevent="minus"
            @mousedown.stop
          >
            <v-icon>mdi-minus</v-icon>
          </a>
          <div
            class="val-cnt"
            @click.stop.prevent=""
            @dblclick="toggleShowInput"
            v-long-press="300"
            @long-press-start="toggleShowInput"
            @mousedown.stop
          >
            <span class="val">{{ quantity }} {{ unit }}</span>
            <span class="small">{{ quantityPerUnit }}</span>
          </div>
          <a
            role="button"
            class="plus"
            @click.stop.prevent="plus"
            @mousedown.stop
          >
            <v-icon>mdi-plus</v-icon>
          </a>
        </div>
        <div
          v-else
          class="qnty-manual-input rounded-md"
          :class="{ 'not-empty': quantity > 0 }"
          transition="fab-transition"
        >
          <a
            role="button"
            class="close"
            @click.stop.prevent="toggleShowInput(false)"
            @mousedown.stop
          >
            <v-icon>mdi-close</v-icon>
          </a>
          <v-tooltip
            bottom
            :open-on-hover="false"
            :open-on-click="true"
            max-width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="qntyInput"
                ref="manualInput"
                class="manual-input"
                hide-details
                autofocus
                outlined
                dense
                v-mask="'###'"
                type="number"
                @click.stop.prevent=""
                @keyup.enter="toggleShowInput(true)"
                @keyup.esc="toggleShowInput(false)"
                @mousedown.stop
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <span
              class="qntity-tooltip"
              v-if="
                product.productInfos.QTY_LOCKED != 'true' ||
                  (product.productInfos.QTY_LOCKED == 'true' &&
                    !modulus(qntyInput))
              "
              v-html="
                $t('product.quantity.confirmQntity', {
                  qntyInput: this.qntyInput
                })
              "
              @click="clicked"
            >
            </span>
            <span
              class="qntity-tooltip"
              v-if="
                product.productInfos.QTY_LOCKED == 'true' &&
                  modulus(qntyInput) &&
                  parseInt(qntyInput) <=
                    parseInt(this.product.productInfos.QTY_MULT)
              "
              v-html="
                $t('product.quantity.editQntityMin', {
                  lowerInput: this.lowerInput
                })
              "
              @click="clicked"
            >
            </span>

            <span
              class="qntity-tooltip"
              v-if="
                product.productInfos.QTY_LOCKED == 'true' &&
                  modulus(qntyInput) &&
                  parseInt(qntyInput) >
                    parseInt(this.product.productInfos.QTY_MULT)
              "
              v-html="
                $t('product.quantity.editQntityMinMax', {
                  lowerInput: this.lowerInput,
                  higherInput: this.higherInput
                })
              "
              @click="clicked"
            >
            </span>
          </v-tooltip>
          <a
            role="button"
            class="check"
            @click.stop.prevent="toggleShowInput(true)"
            @mousedown.stop
          >
            <v-icon>mdi-check</v-icon>
          </a>
        </div>
        <v-btn
          class="primary add"
          elevation="0"
          @click.stop.prevent="addToCart(quantity)"
        >
          <v-icon>$cart</v-icon></v-btn
        >
        <v-btn
          v-if="enableAcqBox && product.logisticPalletItems"
          :aria-label="
            `Aggiungi un cartone da ${product.logisticPalletItems} al carrello`
          "
          color="primary"
          outlined
          class="btn-add-box ml-1"
          small
          @click.stop.prevent="
            addToCart(product.logisticPalletItems, undefined)
          "
        >
          <div class="text-uppercase">
            <v-icon color="primary">$cart</v-icon>
            <div>Un collo</div>
            <div class="small">= {{ product.logisticPalletItems }} pz</div>
          </div>
        </v-btn>
      </template>
      <div v-else class="not-available">
        Il prodotto non è disponibile
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.manual-input {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
.qntity-tooltip {
  color: $primary;
  font-size: 13px;
}
.product {
  border: 0px !important;
}
.product-card {
  .description {
    .product-name {
      @media #{map-get($display-breakpoints, 'xs-only')} {
        max-width: 80%;
      }
    }
  }
  .header {
    .icons-box {
      .product-classes-wrapper > div.product-class:nth-of-type(n + 4) {
        display: none;
      }

      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        .product-classes-wrapper > div.product-class:nth-of-type(n + 5) {
          display: none;
        }

        .product-classes-wrapper > div.product-class:nth-of-type(4) {
          display: block;
        }
      }
    }
    height: 36px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      height: 30px;
    }
    width: 100%;
    margin-bottom: 0;
    .icons-box {
      display: flex;
      margin-left: 3px;
    }
  }
  .promo-box-container {
    position: relative;
    top: inherit;
    left: inherit;
    width: 100%;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      position: absolute;
      top: -10px;
      left: 10px;
      width: fit-content;
      max-width: fit-content;
    }
  }
}
.not-available {
  color: red;
  font-size: 12px;
  white-space: normal;
  height: 40px;
  display: flex;
  align-items: flex-end;
  text-align: right;
}
.heart {
  color: black !important;
}
</style>
<script>
//TODO sistemare la visualizzazione della card su iphone SE dimensione della description
// import ProductClass from "./ProductClass";
import ProductAcqLimit from "./ProductAcqLimit";
import ProductPromo from "./ProductPromo";
import ProductPrice from "./ProductPrice";
import addToPriceListDialog from "@/components/priceList/addToPriceListDialog.vue";

import ProductClass from "./ProductClass.vue";
import productMixin from "~/mixins/product";
import clickHandler from "~/mixins/clickHandler";

import analyticsService from "~/service/analyticsService";

import LongPress from "vue-directive-long-press";

import { mask } from "vue-the-mask";
import { mapState, mapGetters } from "vuex";
import { formatProductCode } from "@/service/grosmarketUtils";

export default {
  name: "ProductCard",
  props: ["product", "position"],
  components: {
    ProductAcqLimit,
    ProductPromo,
    ProductPrice,
    ProductClass
  },
  mixins: [productMixin, clickHandler],
  directives: {
    "long-press": LongPress,
    mask: mask
  },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId",
      isAuthenticated: "cart/isAuthenticated",
      isSsoUser: "cart/isSsoAuthenticated",
      hasRole: "cart/hasRole"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    productCode() {
      return formatProductCode(this.product);
    },
    enableAcqBox() {
      return (
        this.product.productInfos?.ENABLE_ACQ_BOX &&
        this.product.productInfos?.ENABLE_ACQ_BOX !== "no"
      );
    }
  },
  data() {
    return {
      wishlistItem: null
    };
  },
  methods: {
    logClickProduct() {
      analyticsService.clickProduct(this.product, this.position);
    },
    removeItemFromList() {
      this.$emit("removeItem", this.product);
    },
    async addToPriceList() {
      const res = await this.$dialog.show(addToPriceListDialog, {
        product: this.product,
        width: 550,
        waitForResult: true,
        dialogClass: "add-to-price-list-dialog"
      });
      if (res) {
        console.log("add to price list OK");
      } else {
        console.log("add to price list not so OK");
      }
    }
  }
};
</script>
